<template>
  <div class="form">
    <!-- Frontend client component -->
    <div id="frontend-client-form" class="form">
      <h2>Editing Existing Frontend Client</h2>
      <div v-if="error" class="err-msg">{{ error }}</div>
      <div v-if="loading">Loading...</div>
      <div v-else class="form-content">
        <div class="form-input">
          <label for="frontend-client-domain"> Domain: </label>
          <input
            id="frontend-client-domain"
            v-model="frontendClientResponse.domain"
            name="domain"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="local-container-name"> Local Container Name: </label>
          <input
            id="local-container-name"
            v-model="frontendClientResponse.local_container_name"
            name="local_container_name"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="ssr-service-domain"> SSR service Domain: </label>
          <input
            id="ssr-service-domain"
            v-model="frontendClientResponse.ssr_service_domain"
            name="ssr_service_domain"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="api-endpoints"> Api Endpoint: </label>
          <select
            id="api-endpoints"
            v-model="api_endpoint_id"
            name="api_endpoint_id"
          >
            <option :value="null">Please select one or leave it empty</option>
            <option
              v-for="apiEndpoint in apiEndpoints"
              :key="apiEndpoint.id"
              :value="apiEndpoint.id"
            >
              {{ formatApiEndpointDomain(apiEndpoint) }}
            </option>
          </select>
        </div>
        <div class="form-input">
          <label for="oauth-clients"> OAuth client: </label>
          <select
            id="oauth-clients"
            v-model="api_oauth_client_id"
            name="oauth_client_id"
          >
            <option :value="null">Please select one or leave it empty</option>
            <option
              v-for="oauthClient in oauthClients"
              :key="oauthClient.id"
              :value="oauthClient.id"
            >
              {{ formatOauthClientIdAndSecret(oauthClient) }}
            </option>
          </select>
        </div>
        <div class="form-input">
          <label for="cloud-storages"> Cloud Storage: </label>
          <select
            id="cloud-storages"
            v-model="cloud_storage_id"
            name="cloud_storage_id"
          >
            <option :value="null">Please select one or leave it empty</option>
            <option
              v-for="cloudStorage in cloudStorages"
              :key="cloudStorage.id"
              :value="cloudStorage.id"
            >
              {{ formatCloudStoragePath(cloudStorage) }}
            </option>
          </select>
        </div>
        <button class="save-btn" @click="save">Save</button>
      </div>
      <!-- Frontend client component -->
    </div>
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";
import {
  ApiEndpoint,
  CloudStorage,
  FrontendClientRequest,
  FrontendClientResponse,
  OauthClient,
} from "../../types";

class EditFrontendClient extends Vue {
  frontendClientResponse: FrontendClientResponse = {
    id: 1,
    domain: "",
    api_endpoint: null,
    oauth_client: null,
    cloud_storage: null,
    local_container_name: "",
    ssr_service_domain: "",
  };
  apiEndpoints: ApiEndpoint[] = [];
  cloudStorages: CloudStorage[] = [];
  oauthClients: OauthClient[] = [];
  api_endpoint_id: number | null = null;
  api_oauth_client_id: number | null = null;
  cloud_storage_id: number | null = null;
  loading: boolean = true;
  error: string = "";

  created() {
    try {
      this.loading = true;
      Promise.all([
        this.getFrontendClient(Number(this.$route.params.id)),
        this.getApiEndpoints(),
        this.getCloudStorageList(),
        this.getOauthClients(),
      ]).then(() => {
        this.setFrontendClientIDs();
        this.loading = false;
      });
    } catch (err) {
      this.error = err.response.data.error.message;
      this.loading = false;
    }
  }

  setFrontendClientIDs() {
    this.api_endpoint_id =
      this.frontendClientResponse?.api_endpoint?.id ?? null;
    this.api_oauth_client_id =
      this.frontendClientResponse?.oauth_client?.id ?? null;
    this.cloud_storage_id =
      this.frontendClientResponse?.cloud_storage?.id ?? null;
  }

  async save() {
    await this.editFrontendClient(Number(this.$route.params.id));
    this.$router.push({ path: "/frontend-client" });
  }

  async getFrontendClient(id: number) {
    if (this.$route.params.id) {
      const response: AxiosResponse<{ data: FrontendClientResponse }> =
        await apiFetch.frontendClient.getFrontendClientDetail(id);
      this.frontendClientResponse = response?.data?.data;
    }
  }

  async editFrontendClient(id: number) {
    const newFrontendClient: FrontendClientRequest = {
      domain: this.frontendClientResponse?.domain,
      local_container_name: this.frontendClientResponse?.local_container_name,
      ssr_service_domain: this.frontendClientResponse?.ssr_service_domain,
      api_endpoint_id:
        this.api_endpoint_id !== null ? Number(this.api_endpoint_id) : null,
      api_oauth_client_id:
        this.api_oauth_client_id !== null
          ? Number(this.api_oauth_client_id)
          : null,
      cloud_storage_id:
        this.cloud_storage_id !== null ? Number(this.cloud_storage_id) : null,
    };
    await apiFetch.frontendClient.updateFrontendClientDetail(
      id,
      newFrontendClient
    );
  }

  async getApiEndpoints() {
    const response: AxiosResponse<{ data: ApiEndpoint[] }> =
      await apiFetch.apiEndpoint.getApiEndpointsList();
    this.apiEndpoints = response?.data?.data;
  }

  async getCloudStorageList() {
    const response: AxiosResponse<{ data: CloudStorage[] }> =
      await apiFetch.cloudStorage.getCloudStorageList();
    this.cloudStorages = response?.data?.data;
  }

  async getOauthClients() {
    const response: AxiosResponse<{ data: OauthClient[] }> =
      await apiFetch.oauthClient.getOauthClientsList();
    this.oauthClients = response?.data?.data;
  }

  formatApiEndpointDomain(apiEndpoint: ApiEndpoint): string {
    return apiEndpoint?.domain ?? "";
  }

  formatOauthClientIdAndSecret(oauthClient: OauthClient): string {
    if (oauthClient?.client_id && oauthClient?.secret) {
      return `id: ${oauthClient.client_id} & secret: ${oauthClient.secret}`;
    } else {
      return "";
    }
  }

  formatCloudStoragePath(cloudStorage: CloudStorage): string {
    if (cloudStorage?.path && cloudStorage?.prefix && cloudStorage?.version) {
      return `${cloudStorage.path}${cloudStorage.prefix}${cloudStorage.version}`;
    } else {
      return "";
    }
  }
}
export default EditFrontendClient;
</script>
